/* This example requires Tailwind CSS v2.0+ */
import cx from "classnames";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { ExternalLinkIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import {
  DEFAULT_STATE,
  COMPLETE_STATE,
  PENDING_STATE,
  ERROR_STATE,
  useNotification,
} from "~/containers/notification";
import { useMemo } from "react";

export const Notification = ({
  show,
  state,
  handleClose,
  title,
  details,
  hash,
}) => {
  const explorerLink = useMemo(() => {
    if (process.env.NEXT_PUBLIC_CHAIN_ID === "80001") {
      return `https://mumbai.polygonscan.com/tx/${hash}`;
    }
    if (process.env.NEXT_PUBLIC_CHAIN_ID === "137") {
      return `https://polygonscan.com/tx/${hash}`;
    }
  }, [hash]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 z-50 flex items-end px-4 py-12 pointer-events-none"
      >
        <div className="flex flex-col items-end w-full space-y-4 justify-self-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={cx(
                "relative max-w-sm overflow-hidden rounded-lg shadow-lg pointer-events-auto w-72 ring-1 ring-black ring-opacity-5",
                {
                  "bg-white": ERROR_STATE !== state,
                  "bg-red-50": ERROR_STATE == state,
                }
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {ERROR_STATE === state ? (
                      <XCircleIcon
                        className="w-5 h-5 mr-3 -ml-1 text-red-400"
                        aria-hidden="true"
                      />
                    ) : state === PENDING_STATE ? (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-blue-500 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : state === COMPLETE_STATE ? (
                      <CheckCircleIcon
                        className="w-6 h-6 mr-3 -ml-1 text-green-400"
                        aria-hidden="true"
                      />
                    ) : null}
                  </div>
                  <div className="w-0 flex-1 pt-0.5">
                    <p className="text-sm font-bold text-gray-900">{title}</p>
                    {typeof details === "function" ? (
                      details()
                    ) : (
                      <p className="mt-1 text-sm text-gray-500">{details}</p>
                    )}
                  </div>
                  {!!hash ? (
                    <div className="flex flex-shrink-0 ml-4">
                      <a
                        href={explorerLink}
                        target="_blank"
                        rel="noreferrer"
                        className="absolute inline-flex text-gray-400 bg-transparent rounded-md top-2 right-2 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <ExternalLinkIcon
                          className="w-5 h-5"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  ) : typeof handleClose === "function" ? (
                    <div className="flex flex-shrink-0 ml-4">
                      <button
                        className="absolute inline-flex text-gray-400 bg-transparent rounded-md top-2 right-2 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleClose}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default function NotificationComponent() {
  const { state, setState, hash } = useNotification();

  const show = state !== DEFAULT_STATE;

  const title =
    state === ERROR_STATE
      ? "Something went wrong"
      : state === PENDING_STATE
      ? "One Moment please"
      : state === COMPLETE_STATE
      ? "Successfully saved!"
      : "";

  const details =
    state === ERROR_STATE
      ? "Please refresh and try again"
      : state === PENDING_STATE
      ? "Working some blockchain magic. This might take a few minutes."
      : state === COMPLETE_STATE
      ? "You just updated the blockchain!"
      : "";

  return (
    <Notification
      show={show}
      state={state}
      setState={setState}
      title={title}
      details={details}
      hash={["true", "false"].includes(hash) ? false : hash}
    />
  );
}
