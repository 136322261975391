import { ChainId } from "@usedapp/core";
import { InjectedConnector } from "@web3-react/injected-connector";

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    ChainId.Localhost,
    ChainId.Hardhat,
    ChainId.Mumbai,
    ChainId.Polygon,
    ChainId.Mainnet,
  ],
});
