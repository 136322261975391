import { useWeb3React } from "@web3-react/core";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { getCookie } from "~/utils/cookie";

const useContainer = () => {
  const token = useMemo(() => getCookie("token"), []);
  const { chainId, account } = useWeb3React();
  const { currentUser } = useCurrentUser();
  const { pathname, isReady } = useRouter();

  const getCoolCatsModalState = () => {
    const hasShownCoolCatsPrompt = getCookie("hasShownCoolCatsPrompt") || 0;
    if (!isReady) return false;
    if (
      (account && currentUser?.id && pathname === "/",
      !Number(hasShownCoolCatsPrompt))
    ) {
      return true;
    }
    return false;
  };

  const getMetamaskModalState = () => {
    const hasMetamask =
      process.browser && typeof window?.ethereum === "undefined";

    // if we're not logged in and home, return false
    if (pathname === "/" && !token) return false;
    return hasMetamask;
  };

  let [chainIdIsValid, setChainIdIsValid] = useState(true);
  let [metamaskModalOpen, setMetamaskModalOpen] = useState(() =>
    getMetamaskModalState()
  );
  let [coolCatsModalOpen, setCoolCatsModalOpen] = useState(() =>
    getCoolCatsModalState()
  );

  useEffect(() => {
    setCoolCatsModalOpen(() => getCoolCatsModalState());
  }, [pathname]);

  useEffect(() => {
    setChainIdIsValid(
      !chainId
        ? true
        : chainId === Number(process.env.NEXT_PUBLIC_CLIENT_CHAIN_ID)
    );
  }, [chainId]);

  return {
    chainIdIsValid,
    metamaskModalOpen,
    setMetamaskModalOpen,
    coolCatsModalOpen,
    setCoolCatsModalOpen,
  };
};

export const { useContainer: useAppState, Provider: AppStateContainer } =
  createContainer(useContainer);
