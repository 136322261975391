import { useMemo } from "react";
import { getCookie } from "~/utils/cookie";
import { useRequest } from "./useRequest";

export const useCurrentUser = (config) => {
  const token = useMemo(() => getCookie("token"), []);
  const {
    status,
    data: currentUser,
    error,
    isFetching,
  } = useRequest(token && "/users/me", config);

  return { status, currentUser, error, isFetching };
};
