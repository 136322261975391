import React from "react";
import { useQuery } from "react-query";
import { getCookie } from "~/utils/cookie";

export const useRequest = (key, config = {}) => {
  const token = React.useMemo(() => getCookie("token"), []);

  return useQuery(
    key,
    async () => {
      if (!key) return;
      const response = await fetch(process.env.NEXT_PUBLIC_API_BASE_URL + key, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    },
    config
  );
};
