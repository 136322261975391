import "regenerator-runtime/runtime";
import Notification from "~/components/notifiction";
import { NotificationContainer } from "~/containers/notification";
import { QueryClient, QueryClientProvider } from "react-query";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { Web3Provider } from "@ethersproject/providers";
import "../styles/globals.css";
import dynamic from "next/dynamic";
import { Web3ReactProvider } from "@web3-react/core";
import useEagerConnect from "~/hooks/useEagerConnect";
import { AppStateContainer } from "~/containers/app";
import { InstallMetamaskModal } from "~/components/InstallMetamaskModal";
import Head from "next/head";

const Web3ReactProviderDefaultSSR = dynamic(
  () => import("~/components/defaultprovider"),
  { ssr: false }
);

const queryClient = new QueryClient();

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const Root = ({ Component, pageProps }) => {
  useEagerConnect();
  useCurrentUser();
  return (
    <NotificationContainer>
      <Component {...pageProps} />
      <Notification />
      <InstallMetamaskModal />
    </NotificationContainer>
  );
};

function MyApp(props) {
  return (
    <>
      <Head>
        <title>Ladder - Proof of Workout NFT</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ReactProviderDefaultSSR getLibrary={getLibrary}>
            <AppStateContainer>
              <Root {...props} />
            </AppStateContainer>
          </Web3ReactProviderDefaultSSR>
        </Web3ReactProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
