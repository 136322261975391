import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { getCookie } from "~/utils/cookie";

export const DEFAULT_STATE = 0;
export const PENDING_STATE = 1;
export const COMPLETE_STATE = 2;
export const ERROR_STATE = 3;

const useContainer = () => {
  let [state, setState] = useState(DEFAULT_STATE);
  let [hash, setHash] = useState("");

  useEffect(() => {
    const hashFromCookie = getCookie("transaction-hash");
    setHash(hashFromCookie);

    if (hashFromCookie) {
      setState(PENDING_STATE);
    }
  }, []);

  useEffect(() => {
    setHash(getCookie("transaction-hash"));

    if (state === COMPLETE_STATE) {
      setTimeout(() => {
        setState(DEFAULT_STATE);
      }, 5000);
    }
  }, [state]);

  const handleSetState = (newState) => {
    setState((current) => {
      if (newState !== COMPLETE_STATE) return newState;
      if ([PENDING_STATE, ERROR_STATE].includes(current)) return newState;
      return current;
    });
  };

  return {
    hash,
    state,
    setState: handleSetState,
  };
};

export const {
  useContainer: useNotification,
  Provider: NotificationContainer,
} = createContainer(useContainer);
